<template>
  <div class="remoteDoorOpening">
    <div class="item-t" @click="isShowPicker = true">
      <div class="item-l">{{ address }}</div>
      <img
        src="./img/arrow.png"
        :class="[{ downArrow: isShowPicker }]"
        alt=""
      />
    </div>
    <div class="item-c">
      <iframe
        :src="iframeSrc"
        frameborder="0"
        name="myFrameName"
        id="myFrameId"
      >
      </iframe>
    </div>
    <div class="item-b">
      <div class="top" @click="handelClickOpenDoor">
        <img v-if="openStatus == 1" src="./img/openDoor.png" alt="" />
        <div class="opening" v-else-if="openStatus == 2">
          <img src="./img/doning.png" alt="" />
          <span class="text">正在开锁…</span>
        </div>
        <img v-else-if="openStatus == 3" src="./img/succeed.png" alt="" />
        <img v-else-if="openStatus == 4" src="./img/fail.png" alt="" />
      </div>
    </div>
    <v-picker
      :defaultIndex="defaultIndex"
      :columns="OpenDoorList"
      :valueShow="isShowPicker"
      :valueKey="valueKey"
      @clickOverlay="clickOverlay"
      @cancel="cancel"
      @confirmPicker="confirmPicker"
    ></v-picker>
    <v-dialog
      v-model="isDialog"
      title="提示"
      @confirm="confirm"
      confirmButtonColor="#3680FF"
    >
      <p
        style="
          text-align: center;
          line-height: 42px;
          margin: 26px 0 36px 0;
          color: rgba(0, 0, 0, 0.5);
        "
      >
        管理员是否确认进行远程开门？
      </p>
    </v-dialog>
  </div>
</template>

<script>
import { getOpenDoorList, openDoorUrl } from "./api.js";
import { mapState } from "vuex";
export default {
  name: "remoteDoorOpening",
  components: {},
  props: {},
  data() {
    return {
      openStatus: 1, //控制是否正在开门 1:默认 2：正在开门 3：开门成功 4：开门失败
      address: "", //设备
      isShowPicker: false, //picker显示or隐藏
      isDialog: false, //弹窗
      valueKey: "hotspotName",
      iframeSrc: "", //地址
      deviceId: "", //设备id
      OpenDoorList: [], //开门数据
      openTimeOut: null, //延时器
      defaultIndex: 0, //默认选中
    };
  },
  async created() {
    await this.getOpenDoorList();
  },
  mounted() {},
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  watch: {},
  methods: {
    //获取开门列表
    getOpenDoorList() {
      this.$axios.get(getOpenDoorList).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          this.address = data[0].hotspotName;
          this.deviceId = data[0].deviceId;
          this.iframeSrc = `https://wjk.zhuneng.cn/d3mdoc/#/iframe/wjk?id=${this.deviceId}`;
          this.OpenDoorList = data;
        }
      });
    },
    handelClickOpenDoor() {
      if (
        this.openStatus == 2 ||
        this.openStatus == 3 ||
        this.openStatus == 4
      ) {
        return;
      }
      this.isDialog = true;
    },
    //确认
    confirm() {
      this.isDialog = false;
      this.openStatus = 2;
      const params = {
        deviceId: this.deviceId,
        userId: this.userId,
      };
      this.$axios
        .post(openDoorUrl, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.openStatus = 3;
          } else {
            this.openStatus = 4;
          }
        })
        .finally((res) => {
          this.openTimeOut = setTimeout(() => {
            this.openStatus = 1;
          }, 3000);
          this.$toast(res.msg);
        });
    },
    confirmPicker(value) {
      let iframe = document.getElementById("myFrameId");
      iframe.contentWindow.postMessage(
        {
          type: "f_select",
          data: {
            device_id: value.deviceId,
          },
        },
        "*"
      );
      this.address = value.hotspotName;
      this.deviceId = value.deviceId;
      this.isShowPicker = false;
    },
    cancel() {
      this.isShowPicker = false;
    },
    clickOverlay() {
      this.isShowPicker = false;
    },
  },
  beforeDestroy() {
    clearTimeout(this.openTimeOut);
  },
};
</script>

<style scoped lang="less">
.remoteDoorOpening {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 26px 28px 50px 28px;
  background: #ffffff;
  box-sizing: border-box;
  ::v-deep .v-dialog {
    .van-dialog__header {
      height: 44px;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 44px;
    }
  }
  .item-t {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
    border-radius: 16px;
    padding: 26px 46px;
    .item-l {
      flex: 1;
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
    }

    img {
      width: 26px;
      height: 12px;
      &.downArrow {
        transform: rotate(180deg);
      }
    }
  }
  .item-c {
    margin: 30px 0 120px 0;
    width: 100%;
    height: calc(100vh - 536px);
    overflow: hidden;
    border-radius: 16px;
    iframe {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
    }
  }
  .item-b {
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      width: 240px;
      height: 240px;
      img {
        width: 100%;
        height: 100%;
      }
      .opening {
        width: 100%;
        height: 100%;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(0deg);
          width: 100%;
          height: 100%;
          animation: spin 5s linear infinite;
        }

        @keyframes spin {
          from {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          to {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
        .text {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #9874e7;
          line-height: 34px;
        }
      }
    }
  }
}
</style>
